export const domains = [{
    ID: 1,
    DOMAIN: 'sub.domain.com',
    CLIENT: 'Kundenname',
    CMS: 1,
    VERSION: '5.3.12',
    STAGING: 0,
    NOTE: '2018-10-ff cvc hier kommt ein neuer Termin mit einem …exklusiven Thema, welches es zu …',
    DETECTIONID: 1,
},
    {
        ID: 2,
        DOMAIN: 'domain.com/pfad/unterPfad',
        CLIENT: 'Kundenname lang',
        CMS: 2,
        VERSION: '4.0.2',
        STAGING: 1,
        NOTE: 'Update angeboten am 2018-01-05',
        DETECTIONID: 2,
    },
    {
        ID: 3,
        DOMAIN: 'sub.domain.com',
        CLIENT: 'Kundenname',
        CMS: 3,
        VERSION: '5.3.12',
        STAGING: 1,
        NOTE: '2018-10-ff cvc hier kommt ein neuer Termin mit einem …exklusiven Thema, welches es zu …',
        DETECTIONID: 1,
    },
    {
        ID: 4,
        DOMAIN: 'domain.com/pfad/unterPfad',
        CLIENT: 'Kundenname lang',
        CMS: 1,
        VERSION: '4.0.2',
        STAGING: 1,
        NOTE: 'Update angeboten am 2018-01-05',
        DETECTIONID: 1,
    },
    {
        ID: 5,
        DOMAIN: 'sub.domain.com',
        CLIENT: 'Kundenname',
        CMS: 4,
        VERSION: '5.3.12',
        STAGING: 0,
        NOTE: '2018-10-ff cvc hier kommt ein neuer Termin mit einem …exklusiven Thema, welches es zu …',
        DETECTIONID: 1,
    },
    {
        ID: 6,
        DOMAIN: 'domain.com/pfad/unterPfad',
        CLIENT: 'Kundenname lang',
        CMS: 5,
        VERSION: '4.0.2',
        STAGING: 1,
        NOTE: 'Update angeboten am 2018-01-05',
        DETECTIONID: 2,
    },
    {
        ID: 7,
        DOMAIN: 'sub.domain.com',
        CLIENT: 'Kundenname',
        CMS: 1,
        VERSION: '5.3.12',
        STAGING: 0,
        NOTE: '2018-10-ff cvc hier kommt ein neuer Termin mit einem …exklusiven Thema, welches es zu …',
        DETECTIONID: 2,
    },
    {
        ID: 8,
        DOMAIN: 'domain.com/pfad/unterPfad',
        CLIENT: 'Kundenname lang',
        CMS: 1,
        VERSION: '4.0.2',
        STAGING: 0,
        NOTE: 'Update angeboten am 2018-01-05',
        DETECTIONID: 2,
    },
    {
        ID: 9,
        DOMAIN: 'sub.domain.com',
        CLIENT: 'Kundenname',
        CMS: 3,
        VERSION: '5.3.12',
        STAGING: 0,
        NOTE: '2018-10-ff cvc hier kommt ein neuer Termin mit einem …exklusiven Thema, welches es zu …',
        DETECTIONID: 2,
    },
    {
        ID: 10,
        DOMAIN: 'domain.com/pfad/unterPfad',
        CLIENT: 'Kundenname lang',
        CMS: 6,
        VERSION: '4.0.2',
        STAGING: 1,
        NOTE: 'Update angeboten am 2018-01-05',
        DETECTIONID: 1,
    },
    {
        ID: 11,
        DOMAIN: 'sub.domain.com',
        CLIENT: 'Kundenname',
        CMS: 2,
        VERSION: '5.3.12',
        STAGING: 0,
        NOTE: '2018-10-ff cvc hier kommt ein neuer Termin mit einem …exklusiven Thema, welches es zu …',
        DETECTIONID: 1,
    },
    {
        ID: 12,
        DOMAIN: 'domain.com/pfad/unterPfad',
        CLIENT: 'Kundenname lang',
        CMS: 6,
        VERSION: '4.0.2',
        STAGING: 1,
        NOTE: 'Update angeboten am 2018-01-05',
        DETECTIONID: 2,
    },
    {
        ID: 13,
        DOMAIN: 'sub.domain.com',
        CLIENT: 'Kundenname',
        CMS: 5,
        VERSION: '5.3.12',
        STAGING: 1,
        NOTE: '2018-10-ff cvc hier kommt ein neuer Termin mit einem …exklusiven Thema, welches es zu …',
        DETECTIONID: 2,
    },
    {
        ID: 14,
        DOMAIN: 'domain.com/pfad/unterPfad',
        CLIENT: 'Kundenname lang',
        CMS: 3,
        VERSION: '4.0.2',
        STAGING: 0,
        NOTE: 'Update angeboten am 2018-01-05',
        DETECTIONID: 1,
    },

    {
        ID: 15,
        DOMAIN: 'domain.com/pfad/unterPfad',
        CLIENT: 'Kundenname lang',
        CMS: 6,
        VERSION: '4.0.2',
        STAGING: 1,
        NOTE: 'Update angeboten am 2018-01-05',
        DETECTIONID: 2,
    },
    {
        ID: 16,
        DOMAIN: 'sub.domain.com',
        CLIENT: 'Kundenname',
        CMS: 5,
        VERSION: '5.3.12',
        STAGING: 0,
        NOTE: '2018-10-ff cvc hier kommt ein neuer Termin mit einem …exklusiven Thema, welches es zu …',
        DETECTIONID: 1,
    },
    {
        ID: 17,
        DOMAIN: 'domain.com/pfad/unterPfad',
        CLIENT: 'Kundenname lang',
        CMS: 3,
        VERSION: '4.0.2',
        STAGING: 1,
        NOTE: 'Update angeboten am 2018-01-05',
        DETECTIONID: 2,
    },
    {
        ID: 18,
        DOMAIN: 'sub.domain.com',
        CLIENT: 'Kundenname',
        CMS: 6,
        VERSION: '5.3.12',
        STAGING: 1,
        NOTE: '2018-10-ff cvc hier kommt ein neuer Termin mit einem …exklusiven Thema, welches es zu …',
        DETECTIONID: 2,
    },
    {
        ID: 19,
        DOMAIN: 'domain.com/pfad/unterPfad',
        CLIENT: 'Kundenname lang',
        CMS: 1,
        VERSION: '4.0.2',
        STAGING: 1,
        NOTE: 'Update angeboten am 2018-01-05',
        DETECTIONID: 1,
    },


];

export const DETECTION_AUTO = 1;
export const DETECTION_MANUAL = 2;
export const detections = [{
    ID: DETECTION_AUTO,
    Name: 'AUTO',
}, {
    ID: DETECTION_MANUAL,
    Name: 'MANUAL',
}];


export const cms=[
    {
        ID:1,
        Name: 'Vimp',
    },
    {
        ID:2,
        Name: 'Typo3',
    },
    {
        ID:3,
        Name: 'WordPress',
    },
    {
        ID:4,
        Name: 'Joomla',
    },
    {
        ID:5,
        Name: 'Drupal',
    },
    {
        ID:6,
        Name: 'Shopware',
    }
]

export const staging=[
    { id: 0,
      text: 'Produktiv',
    },
    { id: 1,
      text: 'Vorschau',
    },
]
const locales = [
    {
        Name: 'EN',
        Value: 'en',
    },
    {
        Name: 'DE',
        Value: 'de',
    },
];


const dictionary = {
    en: {
        DOMAIN: 'DOMAIN',
        CLIENT: 'CLIENT',
        CMS: 'CMS',
        VERSION: 'VERSION',
        STAGING: 'STAGING',
        NOTE: 'NOTE',
        DETECTION: 'DETECTION',
        ACTIONS: 'ACTIONS',
        SEARCH:'type here to search…',
        PAGERINFO:'Showing {0} to {1} of {2} entries',
        MSGCONFIRMATIONDIALOG: 'Are you sure you want to delete this record?',
        TITLECONFIRMATIONDIALOG:'Confirm delete',

    },
    de: {
        DOMAIN: 'DOMÄNE',
        CLIENT: 'KUNDE',
        CMS: 'CMS',
        VERSION: 'VERSION',
        STAGING: 'STAGING',
        NOTE: 'NOTIZ',
        DETECTION: 'AKTUALISIERUNG',
        ACTIONS: 'AKTIONEN',
        SEARCH:'Schreibe hier um zu suchen…',
        PAGERINFO:'Es werden {0} bis {1} von {2} Einträgen angezeigt',
        MSGCONFIRMATIONDIALOG: 'Möchten Sie diesen Datensatz wirklich löschen?',
        TITLECONFIRMATIONDIALOG:'Löschen bestätigen',
    },

};





let maxID = domains[domains.length - 1].ID;

export default {
    getMaxID() {
        maxID += 1;
        return maxID;
    },
    getDomains() {
        return domains;
    },

    getLocales() {
        return locales;
    },
    getDictionary() {
        return dictionary;
    },


};
