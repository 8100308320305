import React from 'react';

import logoImg from "./components/images/iscope_logo.png"
// import {createElement} from "react";
console.log("test");
export  function SetId(className,idName) {
    let clock = setInterval(() => {
        clearInterval(clock)
        clock = null

        var elem = document.getElementsByClassName(className);
        elem[0].setAttribute('id', idName);
    }, 200)
}

// export function setColorForDetection(){
//     let clock = setInterval(() => {
//         clearInterval(clock)
//         clock = null
//
//         const detectionList = document.querySelectorAll('[aria-describedby="dx-col-7"]');
//         for (let i = 0; i < detectionList.length; i++) {
//             if (detectionList[i].innerText==="AUTO"){
//                 detectionList[i].style.color = "#FF6B00";
//                 //console.log(detectionList[i].innerText);
//             }
//             if (detectionList[i].innerText==="MANUAL"){
//                 detectionList[i].style.color = "#3E3E3E";
//                 //console.log(detectionList[i].innerText);
//             }
//         }
//
//     }, 200)
// }



export function cloneElement(nodeElement, cloneElement){
    let clock = setInterval(() => {
        clearInterval(clock)
        clock = null

    const node = document.getElementById(nodeElement);
    const clone = node.cloneNode(true);
    document.getElementById(cloneElement).appendChild(clone);

    }, 200)
}


// export function pagerInfoColor(){
//     let clock = setInterval(() => {
//         clearInterval(clock)
//         clock=null
//
//         const element = document.getElementById("info-for-pager"),
//         text = element.innerHTML.split('').map(function(el) {
//
//             return '<span class="char-' + el.toLowerCase() + '">' + el +'</span>';
//         }).join('');
//         element.innerHTML = text;
//
//         for (let i = 0; i <element.innerText.length;  i++) { // mit diesem ForSchleife gebe ich ein Number mit className 'char-' für jeder runde gebe ich ein zahl, dann vergleichen wir die className mit dem Gegeben className
//             var str = "char-"
//             var num = i;
//             str += num;
//
//             const childElement = document.getElementById('info-for-pager');
//             for (let j = 0; j < childElement.children.length; j++) { // mit diesem ForSchleife bringe ich Klass Name für alle Kinder Elementen unter diesem ID
//                 let className=childElement.children[j].classList.value
//
//                 if (str===className){
//                    const colorNumber=document.getElementsByClassName(str);
//                     var k;
//                     for (k = 0; k < colorNumber.length; k++) { // Mit desem For schleife bringen wir alle elementen, mit gleichem className
//                         colorNumber[k].style.color = "#FF6B00";
//                     }
//                 }
//
//
//             }
//         }
//
//     },200)
//
// }
//
//


export function iscopeLogo(){
    return(
        <div>
            <img src={logoImg} alt={"logo"}/>
        </div>
    )
}

function wrappelement(){
    let clock = setInterval(() => {
        clearInterval(clock)
        clock = null

        var select_language =document.querySelector('.item-select-language');
        var add_domain =document.querySelector('.item-add-domain');

        var wrapper = document.createElement('div');
        wrapper.classList.add('wrapper_language_domain');

        select_language.parentNode.insertBefore(wrapper, select_language);
        add_domain.parentNode.insertBefore(wrapper, add_domain);
        wrapper.appendChild(select_language);


        wrapper.appendChild(add_domain);

    }, 200)
}

wrappelement();



function wrapperSelectBoxButtonSearch(){
    let clock = setInterval(() => {
        clearInterval(clock)
        clock = null

        var language_domain =document.querySelector('.wrapper_language_domain');
        var search_domain =document.querySelector('.item-search-domain');

        var wrapper = document.createElement('div');
        wrapper.classList.add('wrapper_domain_language_search');

        language_domain.parentNode.insertBefore(wrapper, language_domain);
        search_domain.parentNode.insertBefore(wrapper, search_domain);
        wrapper.appendChild(language_domain);


        wrapper.appendChild(search_domain);

    }, 200)
}

wrapperSelectBoxButtonSearch();


function changeIcon(){
    let clock = setInterval(() => {
        clearInterval(clock)
        clock = null


// // The first element that matches (or null if none do):
//         var element = document.querySelector('[aria-label="Display additional data"]');
//         console.log(element.ariaLabel);
//
//
// // A list of matching elements (empty if none do):
//         var list = document.querySelectorAll('[aria-label="Message Body"]');


        // var inputs = document.querySelectorAll("td");
        // console.log(inputs.length);
        // for(var i = 0; i < inputs.length; i++){
        //     inputs[i].addEventListener("click", function(e){
        //         console.log(this.getAttribute("aria-label"));
        //     });
        // }

        // const element = document.querySelector('[aria-label="Hide additional data"]');
        // console.log(element); // 👉️ button
        //
        // // const el2 = document.querySelector('[aria-label]');
        // // console.log(el2); // 👉️ button
        // const el3 = document.querySelectorAll('[aria-label^="Display"]');
        // console.log(el3); // 👉️ button

        // var element = document.getElementsByClassName("dx-command-adaptive");
        // console.log(element)
        // console.log(element.classList.contains("dx-cell-focus-disabled"))

        // if (hasClass(element,"dx-cell-focus-disabled")){
        //     console.log("ich habe den class gefunden");
        // }else {
        //     console.log("ich habe das nicht gefunde");
        // }
    }, 200)
}

changeIcon();


