import React, {Component} from 'react';
// import Header from "./components/Header";
// import LogIn from "./components/LogIn";
import {BrowserRouter,Switch,Route} from "react-router-dom";
import DataTable from "./components/DataTable";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import './Style.css';
import LogIn from "./components/LogIn";
 //import './loader.js';
// import 'bootstrap/dist/css/bootstrap.css';

class App extends Component{
  render() {
    return(
        <div>
            {/*<BrowserRouter>*/}
            {/*    <Switch>*/}
            {/*        <Route  path={"/login"} component={LogIn}/>*/}
            {/*        <Route  path={"/home"} component={DataTable}/>*/}
            {/*    </Switch>*/}
            {/*</BrowserRouter>*/}
          {/*<Header />*/}
          {/*<LogIn />*/}
          <DataTable />
        </div>

    );
  }
}
export default App;
