import React from 'react';
import DataGrid, {
    Selection, Pager, Paging, Column, Editing, Toolbar, SearchPanel, Item
    , Lookup, Form, Popup,
} from 'devextreme-react/data-grid';
import logoImg from "./images/iscope_logo.png"
import SelectBox from 'devextreme-react/select-box';
// import deMessages from 'npm:devextreme/localization/messages/de.json!json';
// import ruMessages from 'npm:devextreme/localization/messages/ru.json!json';
import {locale, loadMessages, formatMessage} from 'devextreme/localization';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'devextreme-react/text-area';
import service, {domains, detections, DETECTION_AUTO, DETECTION_MANUAL, cms, staging} from './data.js';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import {Button as DeleteButton} from 'devextreme-react/button';
import {Button as ActionButton} from 'devextreme-react/button';
import {Button as LogoutButton} from 'devextreme-react/button';
import {iscopeLogo, SetId} from "../loader";
// import {sanitizeHtml} from "bootstrap/js/src/util/sanitizer";
// import {getClassName} from "devextreme-react/core/widget-config";
// import RadioGroup from 'devextreme-react/radio-group';
// import {useHistory} from "react-router-dom";
import { confirm } from 'devextreme/ui/dialog';
// import { Item} from 'devextreme-react/form';
const rowAlternationEnabled = true;
const allowedPageSizes = [5, 15, 'all'];

const dataSource = new DataSource({
    store: new ArrayStore({
        data: domains,
        key: 'ID',
    }),
});


const selectBoxInputAttr = {id: 'selectInput'};


const notesEditorOptions = {height: 100};

class DataTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayMode: 'full',
            showPageSizeSelector: true,
            showInfo: true,
            showNavButtons: true,
            domains: service.getDomains(),
            selectedItemKeys: [],
            locale: this.getLocale(),
        };

        this.allowDeleting = this.allowDeleting.bind(this);
        this.onRowValidating = this.onRowValidating.bind(this);
        this.onEditorPreparing = this.onEditorPreparing.bind(this);
        this.isCloneIconVisible = this.isCloneIconVisible.bind(this);
        this.isCloneIconDisabled = this.isCloneIconDisabled.bind(this);
        this.cloneIconClick = this.cloneIconClick.bind(this);
        this.selectionChanged = this.selectionChanged.bind(this);
        this.deleteRecords = this.deleteRecords.bind(this);

        this.isScreenGreatThan = this.isScreenGreatThan.bind(this);

        this.locales = service.getLocales();
        this.domains = service.getDomains();
        this.initMessages();
        locale(this.state.locale);
        this.changeLocale = this.changeLocale.bind(this);
        this.dataGridRef = React.createRef();
        this.deleteButtonRef = React.createRef();


    }


    isScreenGreatThan() {
        // let yes=true;
        // let no=false;


        // window.onresize=function() {
        //     if (window.matchMedia("(max-width: 1200px)").matches) {
        //         console.log("Bildschirm ist kleiner als 1200",true)
        //         return true;
        //     } else {
        //         console.log("Bildschirm ist großer als 1200",false)
        //         return false;
        //     }
        //
        // };


        if (window.matchMedia("(max-width: 1200px)").matches) {
            console.log("Bildschirm ist kleiner als 1200")
            return true;
        } else {
            console.log("Bildschirm ist großer als 1200")
            return false;
        }


        // function handleResize() {
        //     console.log('resized to: ', window.innerWidth)
        //
        //     if (window.innerWidth>1200){
        //         console.log("Bildschirm ist großer als 1200")
        //         return false
        //     }
        //     if (window.innerWidth<1200){
        //         console.log("Bildschirm ist kleiner als 1200")
        //         return true
        //     }
        //
        // }
        // window.addEventListener('resize', handleResize)


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("did Update")
        SetId('dx-info', 'info-for-pager');
        SetId('dx-toolbar-after', 'dx-toolbar-id');
        SetId('dx-toolbar-items-container', 'dx-toolbar-container-id');
        // cloneElement("info-for-pager","dx-toolbar-container-id");
        // pagerInfoColor();

        // SetId('dx-datagrid-adaptive-more','id-adaptive');
        //
        // SetId('dx-command-adaptive','id-adaptive');
        // cloneElement("id-adaptive","dx-select-id");
    }

    componentDidMount() {
        console.log("Mounted")
        // mit SetId method können wir Id für irgend ein ClannName setzen
        SetId('dx-info', 'info-for-pager');
        SetId('dx-toolbar-after', 'dx-toolbar-id');
        SetId('dx-toolbar-items-container', 'dx-toolbar-container-id');

        // SetId('dx-command-adaptive','id-adaptive');
        // SetId('dx-command-select','dx-select-id');

        //setColorForDetection();
        // cloneElement("info-for-pager","dx-toolbar-container-id");
        // pagerInfoColor();

        iscopeLogo();


    }

    // Select Language Methods
    getLocale() {
        const storageLocale = sessionStorage.getItem('locale');
        return storageLocale != null ? storageLocale : 'en';
    }

    setLocale(savingLocale) {
        sessionStorage.setItem('locale', savingLocale);
    }

    initMessages() {
        // loadMessages(deMessages);
        // loadMessages(ruMessages);
        loadMessages(service.getDictionary());
    }

    changeLocale(e) {
        this.setState({
            locale: e.value,
        });
        this.setLocale(e.value);
        document.location.reload();
    }


    // CheackBox Methods
    deleteRecords() {
        if (dataSource) {
            let result = confirm(formatMessage('MSGCONFIRMATIONDIALOG'), formatMessage('TITLECONFIRMATIONDIALOG'));
            this.dataGridRef.current.instance.getSelectedRowsData().forEach((obj) => {
                console.log("KEY is ", obj)
                // dataSource.store().remove(obj.ID);


                result.then((dialogResult) => {
                    if (dialogResult) {
                        dataSource.store().remove(obj.ID);
                        dataSource.reload();
                    }
                });
            });
        }

    }

    selectionChanged(data) {
        if (this.dataGridRef.current.instance.getSelectedRowsData()?.length > 0) {
            this.deleteButtonRef.current.instance.option('disabled', false);
        } else this.deleteButtonRef.current.instance.option('disabled', true);
        // this.setState({
        //     selectedItemKeys: data.selectedRowKeys,
        // });
    }

    isChief(position) {
        return position && ['CEO', 'CMO'].indexOf(position.trim().toUpperCase()) >= 0;
    }

    allowDeleting(e) {
        return !this.isChief(e.row.data.Position);
    }

    onRowValidating(e) {
        const position = e.newData.Position;

        if (this.isChief(position)) {
            e.errorText = `The company can have only one ${position.toUpperCase()}. Please choose another position.`;
            e.isValid = false;
        }
    }

    onEditorPreparing(e) {
        if (e.parentType === 'dataRow' && e.dataField === 'Position') {
            e.editorOptions.readOnly = this.isChief(e.value);
        }
    }

    isCloneIconVisible(e) {
        return !e.row.isEditing;
    }

    isCloneIconDisabled(e) {
        return this.isChief(e.row.data.Position);
    }

    cloneIconClick(e, cellRenderArgs) {
        console.log("cloneIconClick", e, cellRenderArgs)
        const domains = [...this.state.domains];
        const clonedItem = {...cellRenderArgs.row.data, ID: service.getMaxID()};
        // domains.push(  {
        //     ID: 7,
        //     DOMAIN: 'test',
        //     CLIENT: 'test',
        //     CMS: 'test',
        //     VERSION: 'test',
        //     STAGING: 'test',
        //     NOTE: 'test5',
        //     DETECTIONID: 1,
        // },)
        domains.splice(cellRenderArgs.row.rowIndex, 0, clonedItem);
        this.setState({domains});
        e.event.preventDefault();
    }


    onToolbarPreparing(e) {
        let toolbarItems = e.toolbarOptions.items;
        // Modifies an existing item
        toolbarItems.forEach(function (item) {
            if (item.name === "addRowButton") {
                console.log(item);
                item.showText = "always"; // modify toolbar item to always display text

                // modify widget button options
                console.log(item.options);
                // See https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxButton/Configuration/ for a list of button options you can configure
                item.options.text = "New Domain";
                item.options.icon = "";
                item.options.elementAttr = {class: "btn-newDomain"}; // adds CSS class to button container
            }
        });
    }


    redirectToLogin = () => {
        const {history} = this.props;
        if (history) history.push('/login');
    }

    render() {

        return (
            <div>
                <DataGrid
                          id="gridContainer"
                          dataSource={dataSource}
                          keyExpr="ID"
                          ref={this.dataGridRef}
                          showBorders={true}

                          columnHidingEnabled={this.isScreenGreatThan()}
                          onRowValidating={this.onRowValidating}
                          rowAlternationEnabled={rowAlternationEnabled}
                    // selectedRowKeys={this.state.selectedItemKeys}
                          onOptionChanged={(e) => {
                              if (e.name === 'paging' && e.fullName === 'paging.pageIndex') {
                                  // const node = document.getElementById("");
                                  // const clone = node.cloneNode(true);
                                  // document.getElementById("info-for-pager").replaceWith(clone);
                                  // pagerInfoColor();

                              }
                          }}
                          onSelectionChanged={this.selectionChanged}
                          onEditorPreparing={this.onEditorPreparing}
                          onContentReady={(e) => {

                              if (this.dataGridRef.current.instance.getSelectedRowsData().length < 1) this.deleteButtonRef.current.instance.option('disabled', true);

                          }}
                          onToolbarPreparing={this.onToolbarPreparing}
                          onCellPrepared={(e) => {

                              //console.log("onCellPrepared",e)
                              if (e.rowType === "data" && e.column.dataField === "DETECTIONID") {
                                  if (e.data[e.column.dataField] === DETECTION_AUTO) e.cellElement.style.color = "#FF6B00";
                                  else if (e.data[e.column.dataField] === DETECTION_MANUAL) e.cellElement.style.color = "#3E3E3E";
                              }
                          }}
                >

                    <Column dataField="DOMAIN" caption={formatMessage('DOMAIN')}/>
                    <Column dataField="CLIENT" caption={formatMessage('CLIENT')}/>
                    <Column dataField="CMS" caption={formatMessage('CMS')}>
                        <Lookup dataSource={cms} valueExpr="ID" displayExpr="Name"/>
                    </Column>
                    <Column dataField="VERSION" caption={formatMessage('VERSION')}/>
                    <Column dataField="STAGING" caption={formatMessage('STAGING')}>
                        <Lookup dataSource={staging} valueExpr="id" displayExpr="text"/>
                    </Column>
                    <Column dataField="NOTE" caption={formatMessage('NOTE')}/>


                    <Editing
                        mode="popup"
                        useIcons={true}
                        allowUpdating={true}
                        allowAdding={true}
                        allowDeleting={this.allowDeleting}>
                        <Popup title="Add Domain" showTitle={true} width={700} height={525}/>

                        <Form>
                            <Item itemType="group" colCount={2} colSpan={2}>
                                <Item dataField="DOMAIN" caption={formatMessage('DOMAIN')}/>
                                <Item dataField="CLIENT" caption={formatMessage('CLIENT')}/>
                                <Item dataField="CMS" caption={formatMessage('CMS')}/>
                                <Item dataField="VERSION" caption={formatMessage('VERSION')}/>
                                <Item dataField="STAGING" caption={formatMessage('STAGING')}/>
                                <Item dataField="DETECTIONID" caption={formatMessage('DETECTION')}/>
                                <Item
                                    dataField="NOTE" caption={formatMessage('NOTE')}
                                    editorType="dxTextArea"
                                    colSpan={2}
                                    editorOptions={notesEditorOptions}
                                />

                            </Item>

                            {/*<Item itemType="group" caption="Home Address" colCount={2} colSpan={2}>*/}
                            {/*    <Item dataField="StateID" />*/}
                            {/*    <Item dataField="Address" />*/}
                            {/*</Item>*/}
                        </Form>

                    </Editing>

                    <Column type="buttons" dataField="ACTIONS" caption={formatMessage('ACTIONS')} width={140}
                            cellRender={(cellRenderArgs) => {
                                return <div className={"actionsButtons"}>
                                    <ActionButton name="edit" icon={"edit"} onClick={(e) => {
                                        this.dataGridRef.current.instance.editRow(cellRenderArgs.rowIndex);
                                    }}/>
                                    <ActionButton name="delete" icon={"close"} onClick={(e) => {
                                        this.dataGridRef.current.instance.deleteRow(cellRenderArgs.rowIndex);
                                    }}/>
                                    <ActionButton hint="Clone" name="delete" icon={"copy"}/>
                                    {/*<Button hint="Clone" icon="copy" visible={this.isCloneIconVisible} disabled={this.isCloneIconDisabled} onClick={this.cloneIconClick} />*/}
                                    {/*<Button name="delete" icon={'close'}/>*/}
                                </div>
                            }}>
                    </Column>

                    {/*<Column dataField="DETECTION" />*/}
                    <Column dataField="DETECTIONID" caption={formatMessage('DETECTION')} width={200}>
                        <Lookup dataSource={detections} valueExpr="ID" displayExpr="Name"/>
                    </Column>
                    <Selection mode="multiple"/>


                    <Paging defaultPageSize={15}/>

                    <SearchPanel visible={true} placeholder={formatMessage('SEARCH')}/>

                    <Toolbar>
                        <Item cssClass={"item-search-domain"} name="searchPanel"/>


                        <Item cssClass={"item-select-language"} location="after">
                            <SelectBox className={"select-language"} items={this.locales}
                                       valueExpr="Value"
                                       displayExpr="Name"
                                       value={this.state.locale}
                                       onValueChanged={this.changeLocale}
                                       inputAttr={selectBoxInputAttr}/>

                        </Item>
                        <Item cssClass={"item-add-domain"} name="addRowButton" showText="always"/>


                        <Item cssClass={"wrapper-Logout-delete-item"} location="after">
                            <LogoutButton className={"btn-Logout"} onClick={this.redirectToLogin}
                                          text="LOGOUT"/>

                            <DeleteButton className={"btn-delete"}
                                          ref={this.deleteButtonRef}
                                          onClick={this.deleteRecords}
                                          icon="trash"
                                // disabled={!this.state.selectedItemKeys.length}
                                          text="Delete"/>


                        </Item>

                        <Item location={"before"} cssClass={"item-iscope-logo"}>
                            <div className={"iscope-logo"}>
                                <img src={logoImg} alt={""} />
                            </div>
                        </Item>

                    </Toolbar>


                    <Pager
                        visible={true}
                        allowedPageSizes={allowedPageSizes}
                        displayMode={this.state.displayMode}
                        showPageSizeSelector={this.state.showPageSizeSelector}
                        showInfo={this.state.showInfo}
                        infoText={formatMessage('PAGERINFO')}
                        //  infoText={'{0}'}
                        showNavigationButtons={this.state.showNavButtons}/>


                </DataGrid>

            </div>

        );


    }


}

export default DataTable;
